import some from 'lodash/some';

const sectionEnabled = (section) => (agreementData) =>
	agreementData.agreement.sections &&
	agreementData.agreement.sections.includes(section);
const propertyRecitals = (section) => (agreementData) =>
	agreementData.agreement.propertyRecitals &&
	agreementData.agreement.propertyRecitals.includes(section);

export default [
	{
		group: 'Basics',
		id: 'recitals',
		title: 'Recitals',
	},
	{
		group: 'Parenting',
		id: 'parenting-responsibilities',
		if: sectionEnabled('Parenting'),
		title: 'Parenting',
	},

	{
		group: 'Parenting',
		id: 'other-parenting-matters',
		// if: sectionEnabled('Parenting'),
		if: (agreementData) =>
		some([
			agreementData.parenting.partner1RestrictionWho,
			agreementData.parenting.partner2RestrictionWho,
			agreementData.parenting.travelClause
		]),

		title: 'Other Parenting Matters',
	},
	{
		group: 'Child Support',
		id: 'child-support-details',
		if: sectionEnabled('Child Support'),
		title: 'Child Support Details',
	},
	{
		group: 'Child Support',
		id: 'special-or-extraordinary-expenses',
		// if: sectionEnabled('Child Support'),
		if: (agreementData) =>{
			if(sectionEnabled('Child Support')(agreementData)){
				if(agreementData.children.length > 0 && agreementData.children.every(child => child.selfSupporting === false)){
					return agreementData.children.some(child => {
						if(child.applicableChildSupportAspects){
							return child.applicableChildSupportAspects.includes('special-extraordinary-expenses') 
						}else{
							return false
						} 
					})
				}
				else{
					
					return true
				}
			}else{
				return false
			}
		},
		title: 'Special or Extraordinary Expenses',
	},
	{
		group: 'Spousal Support',
		id: 'spousal-support',
		if: sectionEnabled('Spousal Support'),
		title: 'Spousal Support Details',
	},
	{
		group: 'Support Additionals',
		id: 'review-and-variation',
		if: (agreementData) =>
			some([
				agreementData.childSupport.reviewable === true,
				agreementData.spousalSupport.reviewable === true,
			]),

		title: 'Review and Variation',
	},

	{
		group: 'Support Additionals',
		id: 'security-for-support',
		if: (agreementData) =>
			some([
				// sectionEnabled('Spousal Support'),
				// sectionEnabled('Child Support'),
				agreementData.supportAdditionals.securityForSupportLifeInsurance
			]),
		title: 'Security for Support',
	},
	{
		group: 'Support Additionals',
		id: 'medical-coverage',
		if: (agreementData) =>
		some(
			[	
				agreementData.supportAdditionals.medicalCoveragePartner1,
				agreementData.supportAdditionals.medicalCoveragePartner2,
				agreementData.supportAdditionals.additionalClausesMedicalCoverage.length > 0
			],
		),
		title: 'Medical Coverage',
	},
	{
		group: 'Property',
		id: 'family-residence',
		if: propertyRecitals('Family Residence'),
		title: 'Family Residence',
	},
	{
		group: 'Property',
		id: 'additional-real-property',
		if: propertyRecitals('Additional Real Property'),
		title: 'Additional Real Property',
	},
	// {
	// 	group: 'Property',
	// 	id: 'businesses',
	// 	if: propertyRecitals('Business'),
	// 	title: 'Business',
	// },
	// {
	// 	group: 'Property',
	// 	id: 'corporations',
	// 	if: propertyRecitals('Corporation'),
	// 	title: 'Corporation',
	// },
	{
		group: 'Property',
		id: 'pensions',
		if: propertyRecitals('Pensions'),
		title: 'Pensions',
	},
	{
		group: 'Property',
		id: 'cpp',
		if: propertyRecitals('Canada Pension Plan'),
		title: 'Canada Pension Plans',
	},
	{
		group: 'Property',
		id: 'rrsp',
		if: propertyRecitals('RRSPs'),
		title: 'RRSPs',
	},
	{
		group: 'Property',
		id: 'resp',
		if: propertyRecitals('RESPs'),
		title: 'RESPs',
	},
	{
		group: 'Property',
		id: 'businesses',
		if: propertyRecitals('Business'),
		title: 'Business',
	},
	{
		group: 'Property',
		id: 'corporations',
		if: propertyRecitals('Corporation'),
		title: 'Corporation',
	},
	{
		group: 'Property',
		id: 'household-and-personal',
		title: 'Household and Personal Belongings',
		if: sectionEnabled('Property'),
	},

	{
		group: 'Property',
		id: 'vehicles',
		if: propertyRecitals('Vehicles'),
		title: 'Vehicles',
	},
	{
		group: 'Property',
		id: 'financial-accounts',
		if: propertyRecitals('Financial Accounts'),
		title: 'Financial Accounts',
	},
	
	{
		group: 'Property',
		id: 'otherProperty',
		if: propertyRecitals('Other Property'),
		title: 'Other Property',
	},

	{
		group: 'Property',
		id: 'liabilities',
		title: 'Liabilities',
		if: sectionEnabled('Property'),
	},
	// {
	// 	group: 'Final Clauses',
	// 	id: 'final-clauses',
	// 	title: 'Conclusions',
	// },
];
