<script>
import format from 'date-fns/esm/format';

export default {
	props: [
		'event',
		'partner1Name',
		'partner2Name',
		'lastEvent',
		'firstEvent',
		'firstAndLastAreSame',
	],
	methods: {
		getDayOfWeek(date) {
			return date ? format(date, 'EEEE') : '';
		},
		getTime(date) {
			return date ? format(date, 'h:mm aaa') : '';
		},
	},
};
</script>

<template>
	<div
		v-if="!(firstAndLastAreSame && event.id == firstEvent.id)"
		class="vuecal__event-title print-font-size"
	>
		{{ event.class === 'partner1' ? partner1Name : partner2Name }}

		<span v-if="firstAndLastAreSame && event.id == firstEvent.id">
			{{ getDayOfWeek(lastEvent.start) }}
			{{ getTime(lastEvent.start) }}
		</span>
		<span v-else>
			{{ getDayOfWeek(event.start) }}
			{{ getTime(event.start) }}
		</span>

		to
		<span v-if="firstAndLastAreSame && event.id == lastEvent.id">
			{{ getDayOfWeek(firstEvent.end) }}
			{{ getTime(firstEvent.end) }}
		</span>
		<span v-else>
			{{ getDayOfWeek(event.end) }}
			{{ getTime(event.end) }}
		</span>
	</div>
</template>

<style>
@media print {
	.print-font-size{
		font-size: 14px;
	}
}
</style>